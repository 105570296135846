<template>
    <div class="content-wrapper">
        <!-- Content Header (Page header) -->
        <div class="content-header">
            <div class="container-fluid">
                <div class="row mb-2">
                    <div class="col-sm-6">
                        <h1 class="m-0 text-dark">All Payment Request</h1>
                    </div><!-- /.col -->
                    <div class="col-sm-6">
                        <!-- <button class="btn btn-info float-right" @click="showCreateModal"><i class="fas fa-plus-circle"></i> Create</button> -->
                    </div><!-- /.col -->
                </div><!-- /.row -->
            </div><!-- /.container-fluid -->
        </div>
        <!-- /.content-header -->
        <!-- Main content -->
        <section class="content">
        <div class="container-fluid">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <div class="row">
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label for="title">Merchant</label>
                                        <v-select name="merchant_id"
                                            v-model="search.merchant_id"
                                            label="text"
                                            :reduce="item => item.id"
                                            :options= getMerchantList
                                            :placeholder="$t('globalTrans.select')"
                                        />
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <label for="date">Date</label>
                                        <input type="date" id="date" v-model="search.date" class="form-control" />
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <div class="form-group">
                                        <button class="btn btn-info btn-sm" type="submit" @click.prevent="searchData" style="margin-top:28px;"><i class="fa fa-search"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-body">
                            <b-overlay :show="loader">
                                <div class="table-responsive">
                                    <table class="table table-bordered table-striped table-sm">
                                        <thead>
                                            <tr>
                                                <th>Merchant</th>
                                                <th>Date</th>
                                                <th>Note</th>
                                                <th>Method</th>
                                                <th>Account Details</th>
                                                <th>Status</th>
                                                <th style="text-align:center;">Action</th>
                                            </tr>
                                        </thead>
                                        <tfoot>
                                            <tr>
                                                <th>Merchant</th>
                                                <th>Date</th>
                                                <th>Note</th>
                                                <th>Method</th>
                                                <th>Account Details</th>
                                                <th>Status</th>
                                                <th style="text-align:center;">Action</th>
                                            </tr>
                                        </tfoot>
                                        <tbody>
                                            <tr v-for="(item,key) in listData" :key="key">
                                                <td>{{ item.merchant ? item.merchant.business : '' }}</td>
                                                <td>{{ item.date }}</td>
                                                <td>{{ item.note }}</td>
                                                <td>{{ getPaymentMethodName(item.payment_method) }}</td>
                                                <td>{{ getAccountDetails(item) }}</td>
                                                <td :class="'status'+item.status">{{ getStatus(item.status) }}</td>
                                                <td style="text-align:center;">      
                                                    <button class="btn btn-warning btn-sm mr-1" @click="edit(item)" title="Edit"><i class="fas fa-pencil-alt"></i></button>
                                                    <button class="btn btn-danger btn-sm" @click="destroy(key, item.id)" title="Delete"><i class="fas fa-trash"></i></button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div class="mt-3">
                                    <b-pagination
                                        v-model="pagination.currentPage"
                                        :per-page="pagination.perPage"
                                        :total-rows="pagination.totalRows"
                                        @input="searchData"
                                    />
                                </div>
                            </b-overlay>
                        </div>
                    </div>
                </div>
            </div>
        </div><!-- /.container-fluid -->
        </section>
        <div v-if="deleteModal" class="modal fade show" style="display:block" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-default" role="document">
                <div class="modal-content">
                    <div class="modal-header bg-info text-white">
                    <h5 class="modal-title text-center w-100">Delete Confirmation</h5>
                    </div>
                    <div class="modal-body">
                        <input type="hidden" name="del_id" />
                        <h3 class="text-center">Are you sure to delete ?</h3>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-info" @click="destroyadjustment">Delete</button>
                        <button type="button" class="btn btn-danger" @click="cancelModal">Cancel</button>
                    </div>
                </div>
            </div>
        </div>
        <!-- /.content -->
    </div>
</template>
<script>
    import config from '@/config'
    export default {
        name:'Adjustment',
        data(){
            return {   
                loader: false,   
                search: {
                    merchant_id: '',
                    amount: '',
                    date: ''
                },
                form:{
                    merchant_id : '',
                    date : '',
                    amount : '',
                    description : ''
                },
                form_edit:'',
                errors:[],
                deleteModal:false,
                key:'',
                merchants: '',
                pagination: {
                    currentPage: 1,
                    totalRows: 0,
                    perPage: this.$store.state.commonObj.perPage,
                    slOffset: 1
                }
            }
        },
        created(){
            if (this.$route.query) {
                Object.assign(this.search, { id: this.$route.query.id })
            } 
            this.loadData();
        },
        watch: {
            stateReload : function (newVal, oldVal) {
                if (newVal != oldVal) {
                    this.loadData()
                }
            }
        },
        computed : {
            stateReload () {
                return this.$store.state.stateReload
            },
            listData () {
                return this.$store.state.list
            },
            getMerchantList: function () {
                return this.$store.state.commonObj.merchantList
            }
        },
        methods:{
            getStatus (status) {
                if (status == 1) {
                    return 'Pending'
                } else if (status == 2) {
                    return 'Approved'
                } else if (status == 2) {
                    return 'Paid'
                }
            },
            getPaymentMethodName (status) {
                if (status == 1) {
                    return 'Bank'
                } else if (status == 2) {
                    return 'Bkash'
                } else if (status == 3) {
                    return 'Nagad'
                } else if (status == 4) {
                    return 'Rocket'
                } else if (status == 5) {
                    return 'Cash'
                }
            },
            getAccountDetails (item) {
                if (item.payment_method == 1) {
                    const bank = this.$store.state.commonObj.bankList.find(el => el.id === parseInt(item.bank_id))
                    if (typeof bank !== 'undefined') {
                        return 'Bank: '+ bank.text + ', Acc. Name:'+ item.account_name + ', Acc. No:'+ item.account_no + ', Routing No: '+ item.routing_no + ' Branch: '+ item.branch
                    }
                } else if (item.payment_method == 2 || item.payment_method == 3 || item.payment_method == 4) {
                    return 'Acc. Name:'+ item.account_name + ', Acc. No:'+ item.account_no
                } else {
                    return 'Cash Payment';
                }
            },
            searchData () {
                this.loadData()
            },
            async loadData(){     
                this.loader = true 
                const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })          
                const response = await config.getData('/accounts/payment-request/list', params)
                this.loader = false
                if (response.success){
                    this.$store.dispatch('setList', response.data.data)
                    this.paginationData(response.data)
                } else {
                    this.$store.dispatch('setList', [])
                } 
            },
            paginationData (data) {
                this.pagination.currentPage = data.current_page
                this.pagination.totalRows = data.total
                this.pagination.perPage = data.per_page
                this.pagination.slOffset = this.pagination.perPage * this.pagination.currentPage - this.pagination.perPage + 1
            },
            cancelModal(){
                this.deleteModal = false;
                document.body.classList.remove("modal-open");
            },
            destroy(key, adjustment_id){ 
                this.adjustment_id = adjustment_id;
                this.key = key;
                this.deleteModal = true;
            },
            async destroyadjustment(){
                this.$store.dispatch('stateReload', true)
                const response = await config.deleteData('/accounts/adjustment/destroy/'+ this.adjustment_id)
                if (response.status == 200) {
                    this.deleteModal = false
                    this.$toast.success({
                        title: 'Success',
                        message: 'Adjustment deleted successfully',
                        color: '#218838'
                    })  
                } else {
                    this.$toast.error({
                        title: 'Error',
                        message: 'Sorry, something went wrong',
                        color: '#dc3545'
                    })  
                }
                this.$store.dispatch('stateReload', false)
            }
        }
    }
</script>
<style>
    .w-35{
        width:35px;
    }
    .status1{
        color: #bfbf06;
        font-weight: bold;
    }
    .status2{
        color: green;
        font-weight: bold;
    }
</style>